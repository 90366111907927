.aboutContainer
{
    display: grid; 
    gap: 15%;

    padding-bottom: 8rem;
}

.aboutMe
{
    width: 100%;
    border-radius: 2rem;

    display: grid;
    place-items: center;
    gap: 3rem;

    grid-template-columns: 35% 50%;
}

.aboutMeImage
{
    border-radius: 1rem;
    overflow: hidden;
    filter: sepia(40%);
    border: 2.5px solid;
    transform: scale(1.2);
    transition: var(--transition-base);
}
.aboutMeImage:hover
{
    transform: scale(1.35);
    filter: sepia(0%);
    border: 4px solid;
    transition: var(--transition-base);
}


/*_______________Media_Rules_____________*/

/*___Tablet___*/
@media screen and (max-width: 1024px)
{
    .aboutMe
    {
        grid-template-columns: 1fr;
        gap: 1rem;
    }
    .aboutMeImage
    {
        transform: scale(0.7);
    }
    .aboutMeImage:hover
    {
        transform: scale(1);
    }
}
/*___Mobile___*/
@media screen and (max-width: 600px)
{
    .aboutMe
    {     
        grid-template-columns: 1fr;
    }
    .aboutMeImage:hover
    {
        transform: scale(1);
    }
}
