.portfolioContainer
{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    gap: 1rem;
    padding-bottom: 8rem;
}


.portfolioItem
{
    background: var(--color-bg);
    background-image: var(--background-texture2);
    padding: 0.5rem;
    border-radius: 1rem;
    transition: var(--transition-base);

    border: 0.5px solid transparent;
}
.portfolioItem:hover
{
    background: var(--color-primary-varient);
    background-image: var(--background-texture);
    border: 0.5px solid;
    transition: var(--transition-base);
}


.portfolioItem:hover > h3::before
{
    transition: var(--transition-base);
    content: "";
    width: 4rem;
    height: 2px;
    background: var(--color-primary-text);
    transition: var(--transition-base);
}
.portfolioItem > h3
{
    margin: 1rem 0 1rem;
    gap: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.portfolioItem:hover > h3::after
{
    transition: var(--transition-base);
    content: "";
    width: 4rem;
    height: 2px;
    background: var(--color-primary-text);
}


.portfolioLinks
{
    display: flex;
    gap: 1rem;

    justify-content: center;
    align-items: center;
    
}


.portfolioImage
{
    overflow: hidden;

    display: flex;
    justify-content: center;
    align-items: center;
    
    padding: 3rem;


}
.portfolioImage > img
{
    border-radius: 0.3rem;
    transition: var(--transition-base);
    /*
    width: calc("100vh - 540px");
    height: calc("100vh - 540px");
    /*
    width: 520px;
    height: 520px;
    */
    overflow: hidden;
    height: (100/3)vh;
    width: (100/3)vw;
    filter: sepia(25%);
    
}
.portfolioImage > img:hover
{
    border: 0.5px solid;
    border-color: var(--color-bg-varient);
    transition: var(--transition-base);
    filter: sepia(0%);
}



/*_______________Media_Rules_____________*/

/*___Tablet___*/
@media screen and (max-width: 1024px)
{
    .portfolioContainer
    {
        grid-template-columns: 1fr 1fr;
        gap: 0.5rem;
       
        /*background-color: blue;*/
    }
    .portfolioImage
    {
        padding: 0.5rem;
    }
}
/*___Mobile___*/
@media screen and (max-width: 600px)
{
    .portfolioContainer
    {    
        grid-template-columns: 1fr;
        gap: 0.1rem;

        /*background-color: red;*/
    }
    .portfolioImage
    {
        padding: 0.8rem;
    }
}