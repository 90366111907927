@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Text:ital@0;1&family=Radio+Canada:wght@300&display=swap');

*{
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}
body 
{
  background: var(--color-bg);
  color: var(--color-primary-text);
  background-image: var(--background-texture2);
  line-height: 1.25;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;

  font-family: 'DM Serif Text', serif;
  font-family: 'Radio Canada', sans-serif;
  
  
  /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;*/
}

:root
{
  --color-bg: #141313;
  --color-bg-varient: #83646483;
  --color-bg-varient2: #251a1a83;
  --color-dark: #070202;
  --color-primary: #6e3831ad;
  --color-primary-varient: rgb(15, 8, 8);
  --color-primary-text: rgb(100, 83, 83); /*(116, 104, 104)*/
  --color-white: #fff;
  --color-offWhite: #ebdbce;
  --color-light: rgba(200, 194, 194, 0.6);

  --background-texture: url(../src/assets/images/broken-noise.png);
  --background-texture2: url(../src/assets/images/45-degree-fabric-dark.png);
  --background-texture-static: url(../src/assets/images/broken-noise-dark.png);

  /*
  #div1 {transition-timing-function: linear;}
  #div2 {transition-timing-function: ease;}
  #div3 {transition-timing-function: ease-in;}
  #div4 {transition-timing-function: ease-out;}
  #div5 {transition-timing-function: ease-in-out;}
  */
  --transition-base: all 400ms ease;

  --container-width-lg: 75%;
  --container-width-md: 85%;
  --container-width-sm: 90%
}

html
{
  scroll-behavior: smooth;
}

::-webkit-scrollbar
{
  display: none; /*block?*/
}

code
{
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*____________General_Styles_____________*/
.container
{
  width: var(--container-width-lg);
  margin: 0 auto;
}

/*1rem equals the font size of the root html element*/
/*1em equals the font size of the local html element*/
section /*<div>*/
{
  margin-top: 6rem;
}

h1,h2,h3,h4,h5
{
  font-weight: 500;
}
h1
{
  font-size: 2.5rem;
}

section > h2,
section > h5
{
  text-align: center;
  color: var(--color-light);
}
/*Override for h2*/
section > h2
{
  color: var(--color-primary-text);
  margin-bottom: 2rem;
}

.text-light
{
  color: var(--color-light);
}

a
{
  color: var(--color-primary-text);
  transition: var(--transition-base);
}
a:hover
{
  color: var(--color-offWhite);
}

/*
Basic Button Bootstrap Styles:
.btn
.btn-default
.btn-primary
.btn-success
.btn-info
.btn-warning
.btn-danger
.btn-link
*/
.btn
{
  width: max-content;
  display: inline-block;
  color: var(--color-bg-varient);

  /*top, right, bottom, left*/
  padding: 0.75rem 1.2rem;

  /*Radius of corners: top-left, top-right, bottom-right, bottom-left*/
  /*1 value = apply to all*/
  border-radius: 0.4rem;

  cursor: pointer;
  border: 1px solid var(--color-bg-varient);
  transition: var(--transition-base);
}
.btn-primary
{
  width: max-content;
  display: inline-block;

  background: var(--color-bg-varient);
  color: var(--color-bg);
  padding: 0.75rem 1.2rem;
  border-radius: 0.4rem;
  border: 0px solid;
}
.btn-other
{
  width: max-content;
  display: inline-block;
  background: var(--color-dark);
  background-image: var(--background-texture2);
  color: var(--color-bg-varient);
  padding: 0.70rem 1rem;
  border-radius: 0.4rem;
  border: 1px solid;
  border-color: var(--color-bg-varient);
  transition: var(--transition-base);
}
.btn-other:hover
{
  color: var(--color-offWhite);
}

.btn:hover
{
  background: var(--color-primary-varient);
  color: var(--color-primary);
  border-color: transparent;
}

img
{
  display: block;
  width: 100%;
  
  /*fill, contain, cover, none, scale-down*/
  object-fit: cover;
}

/*_______________Media_Rules_____________*/

/*___Tablet___*/
@media screen and (max-width: 1024px)
{
  .container
  {
    width: var(--container-width-md);

  }
  section
  {
    margin-top: 6rem;
  }
}
/*___Mobile___*/
@media screen and (max-width: 600px)
{
  .container
  {
    width: var(--container-width-sm);
  }
  section
  {
    margin-top: 2rem;
  }
}
