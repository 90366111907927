header
{
  /*vh = Relative to 1% of the height of browser size*/
  height: 100vh;
  padding-top: 7rem;
  overflow: hidden;
}
.headerContainer
{
  text-align: center;
  height: 100%;
  position: relative;
}

.cva
{
  margin-top: 2rem;
  margin-bottom: 2rem;

  /* Create Button Gap*/
  display: flex;
  gap: 0.7rem;
  justify-content: center;
    
}

.h5
{
  margin-bottom: 3rem;
}

.headerLinks::before
{
  content: "";
  width: 1px;
  height: 3rem;
  background: var(--color-primary-text);
}
.headerLinks
{
  display: flex;
  flex-direction: column-reverse;
  gap: 0.3rem;
  align-items: center; /*Fixes Decorative Line*/
  position: absolute;
  left: 0;
  bottom: 45%;
}
.headerLinks::after
{
  content: "";
  width: 1px;
  height: 3rem;
  background: var(--color-primary-text);
}

.selfPortrait
{
  width: 50%;
  height: 50%;
  
  margin:auto;
  display: block;

  transition: var(--transition-base);
}
.selfPortrait:hover
{   
  transform: scale(1.1);
  transition: var(--transition-base);
}

.scrolldown
{
    transform:rotate(90deg);
    display: flex;
    flex-direction: column-reverse;
    gap: 0.3rem;
    align-items: center; /*Fixes Decorative Line*/
    position: absolute;
    right: 0;
    bottom: 50%;
}

/*___Tablet___*/
/*
@media screen and (max-width: 1024px)
{
  .container
  {
    background: red;
  }
}
*/
/*___Mobile___*/
/*
@media screen and (max-width: 600px)
{
  .container
  {
    background: blue;
  }
}
*/