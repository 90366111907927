nav
{   
    width: max-content;
    display: block;
    border-radius: 1rem;
    padding: 0.6rem 1.2rem; /*Enlarge background*/
    backdrop-filter: sepia(60%);
    backdrop-filter: drop-shadow(4px 4px 10px rgb(24, 15, 13));

    z-index: 13; /*Stack nav elements ontop of other page elements*/

    position: fixed;
    bottom: 1rem;
    left: 85%;
    transform: translateX(-50%);

    display: flex;
    gap: 0.3rem;
}

nav a
{
    background: transparent;
    padding: 0.5rem;
    border-radius: 10%;

    display: flex;  
}