footer
{
    background-color: var(--color-dark);
    background-image: var(--background-texture2);
    padding: 5rem 0;
    text-align: center;
    font-size: 1rem;
    margin-top: 8rem;
}
.homeLink
{
    margin-bottom: 1.5rem;
    border: 1px solid;
    border-right-width: 0;
    border-left-width: 0;
}
.downloadHeader
{
    margin-bottom: 0.3rem;
    border: 1px solid;
    border-right-width: 0;
    border-left-width: 0;
    border-top-width: 0;
}
.footerLinks
{
    padding-bottom: 0.3rem;
    border: 1px solid;
    border-right-width: 0;
    border-left-width: 0;
    border-top-width: 0;
}

.footerSocialMedia
{
    display: flex;
    justify-content: center;
    gap: 3rem;
    margin-top: 3rem;
}

