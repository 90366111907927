.expContainer
{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;

    padding-bottom: 8rem;
}
.expContainer > div
{
    background: var(--color-bg-varient2);
    background-image: var(--background-texture2);
    padding: 1.7rem 1.5rem;
    border-radius: 0.5rem;
    border: 0.5px solid transparent;
    transition: var(--transition-base);
}
.expContainer > div:hover
{
    background: var(--color-bg-varient2);
    border: 0.5px solid;
    transition: var(--transition-base);
}
.expContainer > div h3
{
    text-align: center;
    margin-bottom: 1rem; 
}
.expContainer > div small
{
    text-indent: 1rem;
}

.expContent
{
    row-gap: 2rem;
}


/*_______________Media_Rules_____________*/

/*___Tablet___*/
@media screen and (max-width: 1024px)
{
    .expContainer
    {
        grid-template-columns: 1fr;
    }
    .expContent
    {
        display: grid;
        grid-template-columns: 1fr 1fr;
        row-gap: 2rem;
    }
}
/*___Mobile___*/
@media screen and (max-width: 600px)
{
    .expContainer
    {
        grid-template-columns: 1fr;
    }
    .expContent
    {
        display: grid;
        grid-template-columns: 1fr 1fr;
        row-gap: 2rem;
    }
}