.contactContainer
{  
    display: grid;
    width: 50%;
    grid-template-columns: 30% 50%;
    gap: 20%;
    
}

.contactBlocks
{
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
}

.contactOption1, .contactOption2
{
    background: var(--color-dark);
    background-image: var(--background-texture2);
    padding: 2rem;
    border-radius: 0.5rem;
    text-align: center;
    border: 1px solid;
    transition: var(--transition-base);
}
.contactOption1:hover, .contactOption2:hover
{
    
    padding: 3rem;
    border-radius: 1.5rem;
    border: 1px solid;
    transition: var(--transition-base);
}

.contactOption > h4
{
    margin-bottom: 0.5rem;
}

/* --- Format for Text box input --- */
form
{
    display: flex;
    flex-direction: column;
    gap: 1rem;  
}
input, textarea
{
    background-color: var(--color-dark);
    background-image: var(--background-texture2);
    border-radius: 0.1rem;
    border: 1px solid;
    border-color: var(--color-primary-text);

    resize: none;

    color: var(--color-offWhite);
}

/*_______________Media_Rules_____________*/

/*___Tablet___*/
@media screen and (max-width: 1024px)
{
    .contactContainer
    {
        grid-template-columns: 1fr;
        gap: 1.5rem;
    }
    .contactOption1
    {
        order: 2;
    }
    .contactOption2
    {
        order: 1;
    }
    .icon
    {
        transform: rotate(90deg);
    }
}
/*___Mobile___*/
@media screen and (max-width: 600px)
{
    .contactContainer
    {
        grid-template-columns: 1fr;
        gap: 1rem;
    }
    .contactOption1
    {
        order: 2;
    }
    .contactOption2
    {
        order: 1;
    }
    .icon
    {
        transform: rotate(90deg);
    }
}